<template>
    <main>
        <router-view />
    </main>
</template>
<script>
export default {
    name: "app"
};
</script>
