import VueRouter from "vue-router";
import routes from "./routes";

const router = new VueRouter({
    mode: "history",
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.name !== "Home") {
        const permissions = localStorage.getItem("permissions");
        const permissionsJson = JSON.parse(permissions) ?? [];

        if (to.meta.permission && permissionsJson.length > 0) {
            const result = to.meta.permission.some((item) =>
                permissionsJson.includes(item)
            );

            if (result == false) {
                localStorage.setItem("permissions_error", "403");
                return next("/");
            }
        }
    }

    return next();
});
export default router;
